<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <MainModal
        :main="{ component: 'FlatCounterSubAccount', title: 'Субабоненти' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_sub_account)"
        :modal="show_sub_account_dialog"
        :non-clickable="nonClickable"
        @updateItemModal="subAccountUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Нормативні параметри
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="success" v-if="!readOnly">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openSubAccountCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити нового субабонента</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="houseParameterHeaders"
        :items="flat_flat_counter_sub_accounts"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="onSubAccountItemClick"
    >
      <template v-slot:item.icon="{ item }">
        <v-icon size="26" class="mr-2" :color="getIcon(item.service_icon, 'color')">
          {{ getIcon(item.service_icon, 'icon') }}
        </v-icon>
      </template>

      <template v-slot:item.service_name="{ item }">
        <span :class="{'font-weight-medium': item.actual}">
          {{ item.service_name }}
        </span>
      </template>


      <template v-slot:item.general="{ item }">
        <v-simple-checkbox :value="item.flat_general_id === flat.id" color="success" class="mt-0"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {GET_ALL_FLAT_COUNTER_SUB_ACCOUNT} from "@/store/actions/counter";
import {accountIcons} from "@/utils/icons";

export default {
  name: "HWP_FlatCounterSubAccount",
  components: { MainModal },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    nonClickable: {
      type: Boolean,
      default: false
    },
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      selected_sub_account: {},
      show_sub_account_dialog: false,
      houseParameterHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Послуга', value: 'service_name' },
        { text: 'Основний абонент', value: 'flat_general_address'},
        { text: 'Абонент, покази "мінус"', value: 'flat_minus_address' },
        { text: 'Основний лічильник', value: 'flat_general_counter_number' },
        { text: 'Лічильник, покази "мінус"', value: 'flat_minus_counter_number' },
        { text: 'Основний', value: 'general', width: 110}
      ],
    }
  },
  computed: {
    ...mapGetters({
      flat_flat_counter_sub_accounts: 'getFlatCounterSubAccount',
      flat: 'getFlat',
      flat_legacy: 'getLegacyFlat',
      legacy: 'isLegacy'
    }),
  },
  methods: {
    ...mapActions({
      fetchAllFlatCounterSubAccount: GET_ALL_FLAT_COUNTER_SUB_ACCOUNT
    }),
    getIcon(name, type) {
      const defaultIcon = {icon: 'mdi-text-box-check-outline', color: 'success'}
      const value = accountIcons[name] || defaultIcon
      return value[type]
    },
    subAccountUpdateItemModal(payload) {
      this.show_sub_account_dialog = false
      this.selected_sub_account = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openSubAccountCreateDialog() {
      const payload = {is_legacy: this.legacy}

      if (this.legacy) {
        payload.flat_general_address = this.flat_legacy.full_address
        payload.flat_general_id = this.flat_legacy.id
      } else {
        payload.flat_general_address = this.flat.full_address
        payload.flat_general_id = this.flat.id
      }

      this.selected_sub_account = payload
      this.show_sub_account_dialog = true
    },
    onSubAccountItemClick(payload) {
      this.selected_sub_account = JSON.parse(JSON.stringify(payload))
      this.show_sub_account_dialog = true
    },
  },
  mounted() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      if (this.legacy) {
        this.fetchAllFlatCounterSubAccount({flat_id: this.flat_legacy.id, is_legacy: this.legacy})
      } else {
        this.fetchAllFlatCounterSubAccount({flat_id: this.flat.id, is_legacy: this.legacy})
      }

    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          if (this.legacy) {
            if (this.flat_legacy.id) {
              this.fetchAllFlatCounterSubAccount({flat_id: this.flat_legacy.id, is_legacy: this.legacy})
            } else {
              if (this.flat.id) {
                this.fetchAllFlatCounterSubAccount({flat_id: this.flat.id, is_legacy: this.legacy})
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .menu-without-border {
    box-shadow: none;
  }
</style>